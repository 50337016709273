import { FunctionComponent, ReactNode } from "react";

import { useTranslation } from "react-i18next";

import { useIsAuthorized } from "@Auth/services/use-is-authorized";
import { UnauthorizedPage } from "@Auth/pages/Unauthorized";
import { InProgressPage } from "@Auth/pages/InProgress";
import { ErrorPage } from "@Auth/pages/Error";

type AuthorizationRequiredProps = {
  children: ReactNode;
};

export const AuthorizationRequired: FunctionComponent<
  AuthorizationRequiredProps
> = ({ children }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "auth.sign-in.is-authorized",
  });

  const { isAuthorized, loading, error } = useIsAuthorized();

  if (loading) return <InProgressPage message={t("in-progress.message")} />;

  if (error)
    return <ErrorPage title={t("error.title")} message={t("error.message")} />;

  if (isAuthorized === undefined) return null;

  if (!isAuthorized) return <UnauthorizedPage />;

  return <>{children}</>;
};
