import { FunctionComponent, useEffect, useState } from "react";

import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import {
  getAppNames,
  registerApplication,
  start,
  unregisterApplication,
} from "single-spa";
import { useCurrentUser } from "@Auth/services/use-current-user";

const fotaMifeName = "@emobility/fota";
const commonMifeName = "@emobility/common";

export const MicroFrontends: FunctionComponent = () => {
  const [mainContentEl, setMainContentEl] = useState<HTMLElement | null>();

  const { user, getAccessToken } = useCurrentUser();

  useEffect(() => {
    setMainContentEl(document.getElementById("main-content"));
  }, []);

  useEffect(() => {
    if (mainContentEl) {
      if (user === null) return;

      function showExcept(paths: Array<string>) {
        return (location: Location) =>
          paths.every((path) => !location.pathname.startsWith(path));
      }

      const customProps = {
        user,
        getAccessToken,
      };

      const resolvedRoutes = constructRoutes({
        containerEl: mainContentEl,
        routes: [
          {
            type: "route",
            path: "fota",
            routes: [{ type: "application", name: fotaMifeName }],
            props: customProps,
          },
          {
            type: "route",
            path: "/",
            routes: [{ type: "application", name: commonMifeName }],
            props: customProps,
          },
        ],
        redirects: {
          "/sign-in": "/",
        },
      });

      const applications = constructApplications({
        routes: resolvedRoutes,
        loadApp({ name }) {
          return import(
            /* @vite-ignore */
            name
          );
        },
      });
      const layoutEngine = constructLayoutEngine({
        routes: resolvedRoutes,
        applications,
      });

      applications.forEach((appConfig) => {
        if (appConfig.name === commonMifeName) {
          registerApplication(
            appConfig.name,
            appConfig.app,
            showExcept(["/fota"]),
            customProps
          );
        } else {
          registerApplication(appConfig);
        }
      });
      layoutEngine.activate();

      start();

      return () => {
        const registeredApplications = getAppNames();
        registeredApplications.forEach((name) => {
          unregisterApplication(name);
        });

        layoutEngine.deactivate();
      };
    }
  }, [mainContentEl, user, getAccessToken]);

  return null;
};
