import { useMemo } from "react";

import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

import { Sidebar } from "@destination/components";

import HomeIcon from "@icons/home.svg";
import ChargerIcon from "@icons/ico_charger_16.svg";
import SignOutIcon from "@icons/signOut.svg";
import SoftwareManagementIcon from "@icons/software_management_icon.svg";
import { MenuItem } from "./MenuItem";

export const NavigationMenu = () => {
  const { t } = useTranslation();
  const navigationItems = useMemo(() => {
    const items = [
      {
        icon: <HomeIcon />,
        label: t("home.nav-item.label"),
        url: "/",
        id: "home",
      },
      {
        icon: <SoftwareManagementIcon />,
        label: t("software-management.nav-item.label"),
        url: "/fota/software-packages",
        id: "softwarePackages",
      },
      {
        icon: <ChargerIcon />,
        label: t("deployment-rings.nav-item.label"),
        url: "/fota/deployment-rings",
        id: "deploymentRings",
      },
    ];
    return items;
  }, [t]);

  return <Navigation items={navigationItems} />;
};

export const NavigationFooter = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const handleLogout = () => {
    instance.logout();
  };

  const footerItems = [
    {
      id: "sign-out",
      icon: <SignOutIcon />,
      label: t("auth.sign-out.nav-item.label"),
      url: "/sign-out",
      onMenuClick: handleLogout,
    },
  ];

  return <Navigation items={footerItems} />;
};

const Navigation = ({
  items,
}: {
  items: {
    id: string;
    icon: React.ReactNode;
    label: string;
    url: string;
    onMenuClick?: () => void;
  }[];
}) => {
  return (
    <nav className="flex h-full flex-col justify-between">
      {items.map(({ id, icon, label, url, onMenuClick }) => (
        <MenuItem
          key={`navigation-${id}`}
          data-testid={`navigation-${label}`}
          href={url}
          onClick={onMenuClick}
        >
          {({ isActive }) => (
            <Sidebar.MenuItem
              key={`navigation-${id}`}
              icon={icon}
              label={label}
              isActive={isActive}
            />
          )}
        </MenuItem>
      ))}
    </nav>
  );
};
