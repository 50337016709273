import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import { HelmetProvider } from "react-helmet-async";

import Shell from "@/Shell";

import "../node_modules/@destination/components/dist/index.css";
import "./index.css";
import './i18n';

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance
  .initialize()
  .then(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
      }
    });

    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );
    root.render(
      <StrictMode>
        <MsalProvider instance={msalInstance}>
          <HelmetProvider>
            <Shell />
          </HelmetProvider>
        </MsalProvider>
      </StrictMode>
    );
  })
  .catch((reason: unknown) => {
    console.error("Failed to initialize MSAL instance: " + reason);
  });
