import { useEffect, useState } from "react";
import { useCurrentUser } from "./use-current-user";
import { checker } from "@utils/api/swr-utils";
import axios from "axios";

export function useIsAuthorized(): {
  isAuthorized: boolean | undefined;
  loading: boolean;
  error: Error | undefined;
} {
  const { user, getAccessToken } = useCurrentUser();
  const [isAuthorized, setIsAuthorized] = useState<boolean | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    setLoading(true);
    setIsAuthorized(undefined);
    setError(undefined);

    if (user === null) {
      setLoading(false);
      return;
    }

    const isCurrentUserAuthorized = () => {
      const url = "/users/me";

      return checker(url, getAccessToken);
    };

    isCurrentUserAuthorized()
      .then(() => setIsAuthorized(true))
      .catch((error) => {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          setIsAuthorized(false);
          return;
        }
        setError(new Error(error.message));
      })
      .finally(() => setLoading(false));
  }, [user, getAccessToken]);

  return { isAuthorized, loading, error };
}
