import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en.json";
import translationNL from "./locales/nl.json";
import {
  formatUnknown,
  formatDate,
  formatNumber,
} from "@utils/i18n/formatters";

// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
export default i18n.use(initReactI18next).init({
  lng: "en",
  resources: {
    en: { translation: translationEN },
    nl: { translation: translationNL },
  },
  fallbackLng: "en", // Fallback language if detected language is not available

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: (value: unknown, format?: string, lng?: string): string => {
      if (!value) return "";

      if (!format) return formatUnknown(value);

      if (format.startsWith("date")) {
        return formatDate(value, format, lng);
      }
      if (format.startsWith("number")) {
        return formatNumber(value, format, lng);
      }

      return formatUnknown(value);
    },
  },
});
