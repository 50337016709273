import { FunctionComponent, useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";

export type Props = {
  href: string;
  onClick?: () => void;
  children: ({ isActive }: { isActive: boolean }) => JSX.Element;
};

export const MenuItem: FunctionComponent<Props> = ({
  href,
  onClick,
  children,
}) => {
  const [isActive, setIsActive] = useState(false);

  const location = window.location.pathname;

  useEffect(() => {
    setIsActive(href.startsWith(location));
  }, [href, location]);

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigateToUrl(href);
    }
  };

  return (
    <a className="cursor-pointer" onClick={handleOnClick} data-testid="menuItem">
      {children({ isActive })}
    </a>
  );
};
