import { FunctionComponent } from "react";

import { Card, LoadingIndicator, Typography } from "@destination/components";

type Props = {
  message: string;
};

export const InProgressPage: FunctionComponent<Props> = ({ message }) => {
  return (
    <Card className="self-center my-auto w-[640px] h-[240px] flex items-center justify-center">
      <LoadingIndicator>
        <Typography variant="sectionTitle">{message}</Typography>
      </LoadingIndicator>
    </Card>
  );
};
