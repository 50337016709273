import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Card, Typography } from "@destination/components";

export const SignedOutPage: FunctionComponent = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "auth.sign-out.success",
  });

  return (
    <Card className="self-center my-auto w-[640px] h-[240px] flex flex-col gap-2">
      <Typography variant="pageTitle">{t("title")}</Typography>
      <Typography variant="description" className="whitespace-pre-wrap">
        {t("message")}
      </Typography>
      <a href="/sign-in" className="cursor-pointer">
        {t("sign-in.label")}
      </a>
    </Card>
  );
};
