import Axios from "axios";

Axios.defaults.baseURL = import.meta.env.VITE_APP_BACKEND_API_URL;

const scope = import.meta.env.VITE_APP_BACKEND_API_SCOPE as string;

export const getter = async <T>(
  url: string,
  getAccessToken: (scopes: string[]) => Promise<string>
): Promise<T> => {
  const token = await getAccessToken([scope]);

  const res = await Axios.get<T>(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res.data;
};

export const checker = async (
  url: string,
  getAccessToken: (scopes: string[]) => Promise<string>
): Promise<void> => {
  const token = await getAccessToken([scope]);

  await Axios.head(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
