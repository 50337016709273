import { FunctionComponent } from "react";

import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

import { Card, Typography } from "@destination/components";

export const UnauthorizedPage: FunctionComponent = () => {
  const { instance } = useMsal();

  const { t } = useTranslation(undefined, {
    keyPrefix: "auth.sign-in.is-authorized.unauthorized",
  });

  return (
    <Card className="self-center my-auto w-[640px] h-[240px] flex flex-col gap-2">
      <Typography variant="pageTitle" className="text-red-600">
        {t("title")}
      </Typography>
      <Typography variant="description" className="whitespace-pre-wrap">
        {t("message")}
      </Typography>
      <a
        onClick={() => instance.logout({ postLogoutRedirectUri: "/sign-in" })}
        className="cursor-pointer"
      >
        {t("sign-in.label")}
      </a>
    </Card>
  );
};
