import { FunctionComponent } from "react";

import { InteractionType } from "@azure/msal-browser";

import { navigateToUrl } from "single-spa";

import { Sidebar } from "@destination/components";

import { loginRequest } from "@/authConfig";
import { AuthenticationRequired } from "@Auth/components/AuthenticationRequired";
import { NavigationFooter, NavigationMenu } from "@Shell/components/Navigation";

import { AuthorizationRequired } from "@Auth/components/AuthorizationRequired";

import { SidebarProvider } from "./components/SidebarProvider";
import { Main } from "./Main";

const Shell: FunctionComponent = () => {
  return (
    <AuthenticationRequired
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <AuthorizationRequired>
        <SidebarProvider>
          <Sidebar
            menuItems={<NavigationMenu />}
            footerItems={<NavigationFooter />}
            onClickBranding={() => navigateToUrl("/")}
          />
          <Main />
        </SidebarProvider>
      </AuthorizationRequired>
    </AuthenticationRequired>
  );
};

export default Shell;
