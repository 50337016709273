import { FunctionComponent, useMemo } from "react";

import { useTranslation } from "react-i18next";

import {
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  SsoSilentRequest,
} from "@azure/msal-browser";
import {
  AccountIdentifiers,
  IMsalContext,
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";

import { SignedOutPage } from "@Auth/pages/SignedOut";
import { ErrorPage } from "@Auth/pages/Error";
import { InProgressPage } from "@Auth/pages/InProgress";

type AuthenticationRequiredProps = AccountIdentifiers & {
  interactionType: InteractionType;
  authenticationRequest?: PopupRequest | RedirectRequest | SsoSilentRequest;
  children?: React.ReactNode;
};

export const AuthenticationRequired: FunctionComponent<
  AuthenticationRequiredProps
> = ({
  interactionType,
  username,
  homeAccountId,
  localAccountId,
  authenticationRequest,
  children,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "auth",
  });

  const accountIdentifier: AccountIdentifiers = useMemo(() => {
    return {
      username,
      homeAccountId,
      localAccountId,
    };
  }, [username, homeAccountId, localAccountId]);
  const isAuthenticated = useIsAuthenticated(accountIdentifier);
  const pathname = window.location.pathname;
  if (!isAuthenticated && pathname === "/signed-out") {
    return <SignedOutPage />;
  }

  function ErrorComponent(result: MsalAuthenticationResult) {
    console.error(result.error);
    return (
      <ErrorPage
        title={t("sign-in.error.title")}
        message={t("sign-in.error.message")}
      />
    );
  }

  function InProgressComponent(context: IMsalContext) {
    let message;
    switch (context.inProgress) {
      case InteractionStatus.HandleRedirect:
        message = t("sign-in.success.message");
        break;
      case InteractionStatus.Logout:
        message = t("sign-out.in-progress.message");
        break;

      default:
        message = t("sign-in.in-progress.message");
    }

    return <InProgressPage message={message} />;
  }

  return (
    <MsalAuthenticationTemplate
      username={username}
      homeAccountId={homeAccountId}
      localAccountId={localAccountId}
      interactionType={interactionType}
      authenticationRequest={authenticationRequest}
      loadingComponent={InProgressComponent}
      errorComponent={ErrorComponent}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};
