import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";

import { Card, Typography } from "@destination/components";

type Props = {
  title: string;
  message: string;
};

export const ErrorPage: FunctionComponent<Props> = ({ title, message }) => {
  const { instance } = useMsal();
  const { t } = useTranslation(undefined, {
    keyPrefix: "auth.sign-in.error",
  });

  return (
    <Card className="self-center my-auto w-[640px] h-[240px] flex flex-col gap-2">
      <Typography variant="pageTitle" className="text-red-600">
        {title}
      </Typography>
      <Typography variant="description">{message}</Typography>
      <a onClick={() => instance.logout()} className="cursor-pointer">
        {t("sign-out.label")}
      </a>
    </Card>
  );
};
