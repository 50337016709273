export const getClaimValue = (
  claims:
    | {
        [key: string]: unknown;
      }
    | undefined,
  key: string
): string | null => {
  if (!claims) return null;

  const value = claims[key];
  if (!value) return null;

  return value as string;
};
