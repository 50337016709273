import { Configuration, RedirectRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_APP_CLIENT_ID as string,
    authority: import.meta.env.VITE_APP_AUTHORITY as string,
    knownAuthorities: [import.meta.env.VITE_APP_KNOWN_AUTHORITIES],
    redirectUri: "/auth-response",
    postLogoutRedirectUri: "/signed-out",
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

export const loginRequest: RedirectRequest = {
  scopes: ["openid", "offline_access"],
};
