import { FunctionComponent, useContext } from "react";

import { SidebarContext } from "@destination/components";
import { MicroFrontends } from "./MicroFrontends";

export const Main: FunctionComponent = () => {
  const { isCollapsed } = useContext(SidebarContext);

  return (
    <div
      className={`flex ${isCollapsed ? "ml-[48px]" : "ml-[150px]"}`}
    >
      <main id="main-content" className="flex grow w-full flex-col pb-[53px] pt-16  pr-[120px] transition-all duration-200 sm:ml-12 sm:pl-2 sm:pr-8">
        <MicroFrontends />
      </main>

    </div>
  );
};
